import { combineEpics } from 'redux-observable';
import { of, from } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import {
  logout,
  resumeSession,
  requestAuthToken,
  requestUserProfile,
} from '../../util/api';
import {
  LOGOUT_DISPATCH,
  LOGIN_DISPATCH,
  LOGIN_FULFILL,
  SESSION_RESUME_DISPATCH,
  SESSION_RESUME_FULFILL,
  FETCH_PROFILE_DISPATCH,
  logoutReject,
  logoutFulfill,
  loginReject,
  loginFulfill,
  sessionResumeReject,
  sessionResumeFulfill,
  fetchProfileFulfill,
  fetchProfileReject,
} from '../modules/auth';


export const sessionResumeEpic = action$ => action$.ofType(SESSION_RESUME_DISPATCH)
  .pipe(
    mergeMap(() => from(resumeSession())
      .pipe(
        map(() => sessionResumeFulfill()),
        catchError(error => of(
          sessionResumeReject(error.message),
        )),
      )),
  );

export const logoutEpic = action$ => action$.ofType(LOGOUT_DISPATCH)
  .pipe(
    mergeMap(() => from(logout())
      .pipe(
        map(() => logoutFulfill()),
        catchError(error => of(
          logoutReject(error.message),
        )),
      )),
  );

export const loginEpic = action$ => action$.ofType(LOGIN_DISPATCH)
  .pipe(
    mergeMap(action => from(requestAuthToken(action?.linkToken))
      .pipe(
        map(() => loginFulfill()),
        catchError(error => of(
          loginReject(error.message),
        )),
      )),
  );

export const profileEpic = action$ => action$.ofType(
  FETCH_PROFILE_DISPATCH,
  SESSION_RESUME_FULFILL,
  LOGIN_FULFILL,
)
  .pipe(
    mergeMap(() => from(requestUserProfile())
      .pipe(
        map(response => fetchProfileFulfill(response)),
        catchError(error => of(
          fetchProfileReject(error.response.detail),
        )),
      )),
  );

export default combineEpics(
  sessionResumeEpic,
  logoutEpic,
  loginEpic,
  profileEpic,
);
