export default class STUBAPI {
  constructor() {
    this.getToken = this.getToken.bind(this);
    this.resumeSession = this.resumeSession.bind(this);
    this.requestApplicationPage = this.requestApplicationPage.bind(this);
    this.workEmailAuthenticationRequest = this.workEmailAuthenticationRequest.bind(this);
    this.requestAuthToken = this.requestAuthToken.bind(this);
    this.requestUserProfile = this.requestUserProfile.bind(this);
    this.requestUserVouchers = this.requestUserVouchers.bind(this);
    this.requestUserMessages = this.requestUserMessages.bind(this);
    this.claimUserVoucher = this.claimUserVoucher.bind(this);
    this.updateUserProfileName = this.updateUserProfileName.bind(this);
  }

  getToken = () => Promise.reject(Error('Token not found'));

  resumeSession = () => this.getToken()
    .then(accessToken => accessToken)
    .catch((err) => {
      throw err;
    });

  workEmailAuthenticationRequest = values => new Promise((res) => {
    console.log(values);
    setTimeout(() => res(), 1000);
  });

  requestAuthToken = linkToken => new Promise((res) => {
    // send with temp token in url from email link
    setTimeout(() => res(`{ "authToken": "auth-token-${linkToken}" }`), 1000);
  });

  requestUserProfile = () => new Promise((res) => {
    // request with authentication
    setTimeout(() => res({
      first_name: 'Daniel',
      last_name: 'Streeter',
      email: 'dan@flowmo.co',
      is_verified: false,
      user_id: 3,
    }), 1000);
  })
    .then(({ first_name, last_name, is_verified, user_id, ...rest }) => ({
      fullName: (first_name && last_name) ? `${first_name} ${last_name}` : null,
      isVerified: is_verified,
      userId: user_id,
      ...rest,
    }));

  updateUserProfileName = name => new Promise((res) => {
    console.log(name);
    // respond with user profileFetched
    setTimeout(() => res(), 1000);
  });

  claimUserVoucher = () => new Promise((res) => {
    console.log('claimUserAdvantageCode');
    res('{ "code": 200 }');
  });

  requestApplicationPage = () => new Promise((res) => {
    setTimeout(() => res({
      id: 3,
      meta: {
        type: 'home.HomePage',
        detail_url: 'http://localhost/cms/pages/3/',
        html_url: 'http://localhost/',
        slug: 'home',
        show_in_menus: false,
        seo_title: '',
        search_description: '',
        first_published_at: '2020-05-27T10:41:18.837014+01:00',
        parent: null,
      },
      title: 'Vodafone Advantage',
      sub_title: 'Save up to 15% with',
      hero_body_text: 'Welcome to the Vodafone Advantage scheme. We\'ve partnered With a host of companies and organisations to bring their employees exclusive saving on our pay monthly plans.',
      how_to_buy_blocks: [
        {
          id: 1,
          meta: {
            type: 'home.HomePageHowToBuyRelatedBlocks',
          },
          title: 'Confirm who you are',
          body_text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut suscipit vitae felis non eleifend. Nunc vestibulum velit eget congue hendrerit. Ut ultricies ultricies ullamcorper.',
        },
        {
          id: 2,
          meta: {
            type: 'home.HomePageHowToBuyRelatedBlocks',
          },
          title: 'Receive your discount code',
          body_text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut suscipit vitae felis non eleifend. Nunc vestibulum velit eget congue hendrerit. Ut ultricies ultricies ullamcorper.',
        },
        {
          id: 3,
          meta: {
            type: 'home.HomePageHowToBuyRelatedBlocks',
          },
          title: 'Choose your monthly bundle',
          body_text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut suscipit vitae felis non eleifend. Nunc vestibulum velit eget congue hendrerit. Ut ultricies ultricies ullamcorper.',
        },
      ],
    }), 2000);
  })

  requestUserVouchers = () => new Promise((res) => {
    // request with authentication
    setTimeout(() => res({
      voucherClaimLimit: 8,
      vouchers: [
        {
          urn: 'AA111',
          date_created: 'ul',
          date_claimed: '2020-02-14',
          offer_id: null,
          state: 1,
          sent_to_prospect: null,
          sent_to_user: '2020-02-14',
          activation_date: null,
          activation_type: null,
          date_booked: null,
          user_id: 3,
        },
        {
          urn: 'ABC123',
          date_created: '2020-01-31',
          date_claimed: '2020-02-14',
          offer_id: null,
          state: 1,
          sent_to_prospect: null,
          sent_to_user: '2020-02-14',
          activation_date: null,
          activation_type: null,
          date_booked: null,
          user_id: 3,
        },
        {
          urn: 'TEST3OOA6AHIZ3',
          date_created: '2020-02-04',
          date_claimed: '2020-02-14',
          offer_id: 1,
          state: 1,
          sent_to_prospect: null,
          sent_to_user: '2020-02-14',
          activation_date: null,
          activation_type: null,
          date_booked: null,
          user_id: 3,
        },
      ],
    }), 1000);
  });

  requestUserMessages = () => new Promise((res) => {
    // request with authentication
    setTimeout(() => res({
      messages: [
        {
          id: 'zxcvbnm',
          date_created: 'ul',
          message: 'lorem ipsum',
          user_id: 3,
        },
        {
          id: 'asdfghjkl',
          date_created: '2020-01-31',
          message: 'lorem ipsum',
          user_id: 3,
        },
        {
          id: 'qwertyuio',
          date_created: '2020-02-04',
          message: 'lorem ipsum',
          user_id: 3,
        },
      ],
    }), 1000);
  });
}
