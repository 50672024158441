import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './components/App/App';
import './index.scss';
import store from './redux/store';
// fonts
import './fonts/vodafone-regular.woff';
import './fonts/vodafone-bold.woff';
import './fonts/vodafone-light.woff';


const startApp = () => {
  ReactDOM.render((
    <Provider store={store}>
      <App />
    </Provider>
  ), document.getElementById('app'));
};

if (window.cordova) {
  document.addEventListener('deviceready', () => {
    startApp();
    setTimeout(() => {
      window.navigator.splashscreen.hide();
      window.StatusBar.backgroundColorByHexString('#ECEFF1');
      window.StatusBar.styleDefault();
    }, 1000);
  }, false);
} else {
  startApp();
}
