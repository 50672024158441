import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  workEmailAuthenticationRequest,
  personalEmailAuthenticationRequest,
  registerUploadFile,
} from '../util/api';
import useFileUpload from '../hooks/useFileUpload';
import { fetchApplicationPageDispatch as fetchApplicationPage } from '../redux/modules/pages';


export const ApplicationDataContext = createContext();

const ApplicationDataProvider = ({ children }) => {
  const dispatch = useDispatch();
  const {
    applicationPageFetching,
    title,
    subTitle,
    heroBodyText,
    applicationSteps,
    availableOffers,
  } = useSelector(({ pages }) => ({
    applicationPageFetching: pages.applicationPage.isFetching,
    title: pages.applicationPage.data.title,
    subTitle: pages.applicationPage.data.subTitle,
    heroBodyText: pages.applicationPage.data.heroBodyText,
    applicationSteps: pages.applicationPage.data.applicationSteps || [],
    availableOffers: pages.applicationPage.data.availableOffers || [],
  }));
  const { uploadToFileList } = useFileUpload(registerUploadFile);
  const [hasRequestedEmailAuth, setHasRequestedEmailAuth] = useState(false);
  const [hasRequestedVerification, setHasRequestedVerification] = useState(false);

  const authenticateWorkEmail = values => workEmailAuthenticationRequest(values)
    .then(() => setHasRequestedEmailAuth(true));

  const authenticatePersonalEmail = values => uploadToFileList(values.proofDocument)
    .catch((err) => {
      throw err;
    })
    .then(({ uploadRef }) => personalEmailAuthenticationRequest({
      ...values,
      proofDocumentID: uploadRef,
    }))
    .then(() => setHasRequestedVerification(true));

  useEffect(() => {
    dispatch(fetchApplicationPage());
  }, []);

  return (
    <ApplicationDataContext.Provider
      value={{
        applicationPageFetching,
        title,
        subTitle,
        heroBodyText,
        availableOffers,
        applicationSteps,
        authenticateWorkEmail,
        authenticatePersonalEmail,
        hasRequestedEmailAuth,
        hasRequestedVerification,
      }}
    >
      {children}
    </ApplicationDataContext.Provider>
  );
};

export default ApplicationDataProvider;
