import React from 'react';

// no hook for componentDidCatch yet (05/09/2019) so need to use a class component
export default class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error) {
    console.log(error);
    this.setState({ hasError: true });
  }

  render() {
    const refreshApp = () => {
      window.location = '';
    };
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div>
          <h1>Something went wrong</h1>
          <p>
            Apologies, tap &quot;Reload&quot; to resume from the last saved
            data. If this problem persists please contact support.
          </p>
          <button type="button" onClick={refreshApp}>Reload</button>
        </div>
      );
    }
    return children;
  }
}
