// import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fileAddDispatch,
  fileAddFulfill,
  fileAddReject,
  fileAddProgress,
} from '../redux/modules/files';


const useFileUpload = (uploadAPIMethod) => {
  const dispatch = useDispatch();
  const { fileList } = useSelector(({ files }) => ({ fileList: files.list || {} }));

  const uploadToFileList = file => new Promise((res, rej) => {
    const fileId = Math.random().toString(36).substring(7);
    const progressMethod = perc => dispatch(fileAddProgress(fileId, Math.floor(perc)));

    dispatch(fileAddDispatch(fileId, file.name));
    uploadAPIMethod(file, progressMethod)
      .then((response) => {
        console.log(response);
        dispatch(fileAddFulfill(fileId));
        res(response);
      })
      .catch((err) => {
        dispatch(fileAddReject(fileId, err.message));
        rej(err);
      });
  });


  return {
    fileList,
    uploadToFileList,
  };
};

export default useFileUpload;
