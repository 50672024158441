import stepOneImage from '../../assets/images/icon_deals.svg';
import stepTwoImage from '../../assets/images/icon_badge.svg';
import stepThreeImage from '../../assets/images/icon_trolley.svg';

export const FETCH_APPLICATION_PAGE_DISPATCH = 'VEA/pages/FETCH_APPLICATION_PAGE_DISPATCHED';
export const FETCH_APPLICATION_PAGE_FULFILL = 'VEA/pages/FETCH_APPLICATION_PAGE_FULFILLED';
export const FETCH_APPLICATION_PAGE_REJECT = 'VEA/pages/FETCH_APPLICATION_PAGE_REJECTED';

export const fetchApplicationPageDispatch = () => ({ type: FETCH_APPLICATION_PAGE_DISPATCH });
export const fetchApplicationPageFulfill = (title, subTitle, heroBodyText, howToBuyBlocks, whatCanIGetBlocks) => {
  const iconMap = {
    user: stepOneImage,
    handshake: stepTwoImage,
    cart: stepThreeImage,
  };
  const applicationSteps = howToBuyBlocks.map(step => ({
    iconUrl: iconMap[step.icon],
    title: step.title,
    detail: step.body_text,
  }));
  const availableOffers = whatCanIGetBlocks.map(offer => ({
    title: offer.title,
    usps: offer.body_text.split('\n'),
    actionLabel: offer.title.includes('SIM only') ? 'Browse SIM only plans' : 'Browse the latest devices',
    actionUrl: offer.link_href,
  }));
  return {
    type: FETCH_APPLICATION_PAGE_FULFILL,
    title,
    subTitle,
    heroBodyText,
    applicationSteps,
    availableOffers,
  };
};
export const fetchApplicationPageReject = error => ({ type: FETCH_APPLICATION_PAGE_REJECT, error });

const initialPageState = {
  isFetching: false,
  error: null,
  data: {},
};

const initialState = {
  applicationPage: initialPageState,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_APPLICATION_PAGE_DISPATCH:
      return {
        ...state,
        applicationPage: {
          ...state.applicationPage,
          error: null,
          isFetching: true,
        },
      };

    case FETCH_APPLICATION_PAGE_FULFILL:
      return {
        ...state,
        applicationPage: {
          ...state.applicationPage,
          isFetching: false,
          data: {
            ...state.applicationPage.data,
            title: action.title,
            subTitle: action.subTitle,
            heroBodyText: action.heroBodyText,
            applicationSteps: action.applicationSteps,
            availableOffers: action.availableOffers,
          },
        },
      };

    case FETCH_APPLICATION_PAGE_REJECT:
      return {
        ...state,
        applicationPage: {
          ...state.applicationPage,
          isFetching: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
}
