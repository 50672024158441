export const FILE_ADD_DISPATCH = 'VEA/files/FILE_ADD_DISPATCHED';
export const FILE_ADD_PROGRESS = 'VEA/files/FILE_ADD_PROGRESSED';
export const FILE_ADD_FULFILL = 'VEA/files/FILE_ADD_FULFILLED';
export const FILE_ADD_REJECT = 'VEA/files/FILE_ADD_REJECTED';

export const fileAddDispatch = (id, name) => ({
  type: FILE_ADD_DISPATCH,
  id,
  name,
});
export const fileAddProgress = (id, perc) => ({
  type: FILE_ADD_PROGRESS,
  id,
  perc,
});
export const fileAddFulfill = id => ({
  type: FILE_ADD_FULFILL,
  id,
});
export const fileAddReject = (id, errorMessage) => ({
  type: FILE_ADD_REJECT,
  id,
  errorMessage,
});


const initialState = {
  list: {},
};

const initialFileState = {
  name: '',
  uploadedPerc: 0,
  uploaded: false,
  errorMessage: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FILE_ADD_DISPATCH:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...initialFileState,
            name: action.name,
          },
        },
      };

    case FILE_ADD_PROGRESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            uploadedPerc: action.perc,
          },
        },
      };

    case FILE_ADD_FULFILL:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            uploaded: true,
            errorMessage: null,
          },
        },
      };

    case FILE_ADD_REJECT:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            uploaded: false,
            errorMessage: action?.errorMessage || 'something went wrong uploading.',
          },
        },
      };

    default:
      return state;
  }
}
