import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router';
import { AuthContext } from '../../context/AuthProvider';

const PrivateRoute = ({ component, ...rest }) => {
  const { authenticated } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={props => (
        authenticated
          ? React.createElement(component, props)
          : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          )
      )}
    />
  );
};

export default PrivateRoute;
