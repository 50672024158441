import { of, from } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { requestUserMessages } from '../../util/api';
import {
  FETCH_MESSAGES_DISPATCH,
  fetchMessagesFulfill,
  fetchMessagesReject,
} from '../modules/messages';


export const messagesGetAllEpic = action$ => action$.ofType(FETCH_MESSAGES_DISPATCH)
  .pipe(mergeMap(() => from(requestUserMessages())
    .pipe(
      map(response => fetchMessagesFulfill(response.messages)),
      catchError(error => of(
        fetchMessagesReject(error.message),
      )),
    )));

export default messagesGetAllEpic;
