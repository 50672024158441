import React, { Suspense, lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { sessionResume } from '../../redux/modules/auth';
import ApplicationDataProvider from '../../context/ApplicationDataProvider';
import AuthProvider from '../../context/AuthProvider';
import AppErrorBoundary from '../AppErrorBoundary/AppErrorBoundary';
import Loader from '../Loader/Loader';
import PrivateRoute from '../PrivateRoute/PrivateRoute';

const AuthCheck = lazy(() => import('../../pages/AuthCheck/AuthCheck'));
const ApplyPage = lazy(() => import('../../pages/ApplyPage/ApplyPage'));
const AuthPage = lazy(() => import('../../pages/AuthPage/AuthPage'));
const MessageCenterPage = lazy(() => import('../../pages/MessageCenterPage/MessageCenterPage'));
const DiscountDashboardPage = lazy(() => import('../../pages/DiscountDashboardPage/DiscountDashboardPage'));
const NotFoundPage = lazy(() => import('../../pages/NotFoundPage/NotFoundPage'));


const App = () => {
  const dispatch = useDispatch();
  const {
    authenticated,
    profile,
    reAuthenticating,
  } = useSelector(({ auth }) => ({
    authenticated: auth.authenticated,
    profile: auth.profile,
    reAuthenticating: auth.reAuthenticating,
  }));
  // // Test routing
  // const routes = (
  //   <Switch>
  //     <Route exact path="/application-form" component={ApplyPage} />
  //     <Route exact path="/discount-dashboard-page" component={DiscountDashboardPage} />
  //   </Switch>
  // );

  let routes;
  if (reAuthenticating) {
    routes = (
      <Switch>
        <Route path="/" component={AuthCheck} />
      </Switch>
    );
  } else if (authenticated && profile && profile.isVerified) {
    routes = (
      <Switch>
        <Redirect from="/auth/:linkToken" to="/" />
        <PrivateRoute exact path="/" component={DiscountDashboardPage} />
        <Route path="/*" component={NotFoundPage} />
      </Switch>
    );
  } else if (authenticated && profile && !profile.isVerified) {
    routes = (
      <Switch>
        <Redirect from="/auth/:linkToken" to="/" />
        <PrivateRoute exact path="/" component={MessageCenterPage} />
        <Route path="/*" component={NotFoundPage} />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route exact path="/" component={ApplyPage} />
        <Route path="/auth/:linkToken" component={AuthPage} />
        <Route path="/*" component={NotFoundPage} />
      </Switch>
    );
  }


  useEffect(() => {
    dispatch(sessionResume());
  }, []);

  return (
    <AppErrorBoundary>
      <Suspense fallback={<Loader />}>
        <ApplicationDataProvider>
          <AuthProvider>
            <Router baseline="/">
              {routes}
            </Router>
          </AuthProvider>
        </ApplicationDataProvider>
      </Suspense>
    </AppErrorBoundary>
  );
};

export default App;
