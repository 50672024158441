import { ajax } from 'rxjs/ajax';
import jwtDecode from 'jwt-decode';


const saveToken = (name, token) => Promise.resolve()
  .then(() => localStorage.setItem(name, token));

const loadToken = name => localStorage.getItem(name);

const removeToken = name => Promise.resolve()
  .then(() => localStorage.removeItem(name));

export default class VEAAPI {
  constructor({ domain }) {
    this.domain = domain;
    this.accessToken = loadToken('accessToken');
    this.refreshToken = loadToken('refreshToken');
    this.requestApplicationPage = this.requestApplicationPage.bind(this);
    this.getToken = this.getToken.bind(this);
    this.resumeSession = this.resumeSession.bind(this);
    this.logout = this.logout.bind(this);
    this.unsafeGetToken = this.unsafeGetToken.bind(this);
    this.sendRefreshRequest = this.sendRefreshRequest.bind(this);
    this.createAjaxPromise = this.createAjaxPromise.bind(this);
    this.workEmailAuthenticationRequest = this.workEmailAuthenticationRequest.bind(this);
    this.personalEmailAuthenticationRequest = this.personalEmailAuthenticationRequest.bind(this);
    this.requestAuthToken = this.requestAuthToken.bind(this);
    this.requestUserProfile = this.requestUserProfile.bind(this);
    this.updateUserProfileName = this.updateUserProfileName.bind(this);
    this.claimUserVoucher = this.claimUserVoucher.bind(this);
    this.requestUserVouchers = this.requestUserVouchers.bind(this);
    this.requestUserMessages = this.requestUserMessages.bind(this);
    this.registerUploadFile = this.registerUploadFile.bind(this);
    this.verificationUploadFile = this.verificationUploadFile.bind(this);
    this.postMessage = this.postMessage.bind(this);
  }

  createAjaxPromise = ({ headers, ...customAjaxConfig }) => {
    const ajaxConfig = {
      crossDomain: true,
      responseType: 'json',
      headers: { 'Content-Type': 'application/json' },
    };

    return new Promise(
      (resolve, reject) => ajax({
        ...ajaxConfig,
        ...customAjaxConfig,
        headers: { ...ajaxConfig.headers, ...headers },
      })
        .toPromise()
        .then(response => resolve(response.response))
        .catch(err => reject(err)),
    );
  }

  requestApplicationPage = () => this.createAjaxPromise({
    method: 'GET',
    url: `${this.domain}/cms/pages/home/?format=json`,
  });

  workEmailAuthenticationRequest = ({
    ...rest
  }) => this.createAjaxPromise({
    method: 'POST',
    url: `${this.domain}/user/login/`,
    body: {
      ...rest,
    },
  });

  personalEmailAuthenticationRequest = ({
    company,
    emailConfirm,
    fullName,
    proofDocumentID,
    ...rest
  }) => this.createAjaxPromise({
    method: 'POST',
    url: `${this.domain}/user/register/`,
    body: {
      ...rest,
      company_name: company,
      email_confirm: emailConfirm,
      full_name: fullName,
      proof_document_id: proofDocumentID,
    },
  });

  requestAuthToken = (linkToken) => {
    if (!linkToken) {
      return Promise.reject(Error('Link token required'));
    }

    // If token is already being fetched, wait for that request.
    if (this.authPromise) {
      return this.authPromise;
    }

    this.authPromise = this.createAjaxPromise({
      method: 'GET',
      url: `${this.domain}/user/otp_login/?token=${linkToken}`,
    })
      .then(response => response.jwt_tokens)
      .then(({ access, refresh }) => {
        // store tokens
        this.accessToken = access;
        saveToken('accessToken', access);
        this.refreshToken = refresh;
        saveToken('refreshToken', refresh);
        delete this.authPromise;
        return this.accessToken;
      })
      .catch((res) => {
        delete this.authPromise;
        if (res && res.response && res.response.details) {
          return Promise.reject(Error(res.response.details));
        }
        return Promise.reject(Error('There was an error authenticating'));
      });
    return this.authPromise;
  };

  sendRefreshRequest() {
    return this.createAjaxPromise({
      method: 'POST',
      url: `${this.domain}/token/refresh/`,
      body: { refresh: this.refreshToken },
    })
      .then(({ access }) => {
        // store tokens
        this.accessToken = access;
        saveToken('accessToken', access);
        delete this.authPromise;
        return this.accessToken;
      })
      .catch(() => {
        // Failure to fetch token is most likely to be incorrect
        // credentials, but it could be something else like the
        // internet connection being dropped. Triggering logout
        // is extreme but should be secure in all cases.

        // TODO logout work here
      });
  }

  getToken() {
    return this.unsafeGetToken()
      .catch(() => Promise.reject(Error('Authentication token expired, please re-authenticate')));
  }

  logout() {
    return new Promise((res) => {
      removeToken('accessToken');
      removeToken('refreshToken');
      this.authToken = null;
      this.refreshToken = null;
      res();
    });
  }

  unsafeGetToken() {
    // If token is already being fetched, wait for that request.
    if (this.authPromise) {
      return this.authPromise;
    }

    // Check if we've already got an access token.
    if (this.accessToken) {
      const timeWindow = 15;
      const now = Math.floor(Date.now() / 1000);
      // If the access token's not expired, use it!
      const decodedAccessToken = jwtDecode(this.accessToken);
      const decodedRefreshToken = jwtDecode(this.refreshToken);

      if ((decodedAccessToken.exp - timeWindow) > now) {
        return Promise.resolve(this.accessToken);
      }

      // If access token has expired & we have a valid refresh token, refresh it.
      if ((decodedRefreshToken.exp - timeWindow) > now) {
        this.authPromise = this.sendRefreshRequest();
        return this.authPromise;
      }
    }

    // If the refresh token is also expired, the user needs to re-authenticate.
    this.logout();
    return Promise.reject(Error('Tokens expired, please re-authenticate.'));
  }

  resumeSession = () => this.getToken()
    .then(accessToken => accessToken)
    .catch((err) => {
      throw err;
    });

  requestUserProfile = () => this.getToken()
    .then(accessToken => this.createAjaxPromise({
      method: 'GET',
      url: `${this.domain}/user/profile/`,
      headers: { Authorization: `Bearer ${accessToken}` },
    }))
    .then(({ first_name, last_name, is_verified, user_id, ...rest }) => ({
      fullName: (first_name && last_name) ? `${first_name} ${last_name}` : null,
      isVerified: is_verified,
      userId: user_id,
      ...rest,
    }))
    .catch((res) => {
      if (res instanceof Error) {
        return Promise.reject(res);
      }
      if (res && res.response && res.response.details) {
        return Promise.reject(Error(res.response.details));
      }
      return Promise.reject(Error('There was an error fetching details'));
    });

  requestUserVouchers = () => this.getToken()
    .then(accessToken => this.createAjaxPromise({
      method: 'GET',
      url: `${this.domain}/user/vouchers/`,
      headers: { Authorization: `Bearer ${accessToken}` },
    }))
    .then(({ voucher_claim_limit, ...rest }) => ({
      voucherClaimLimit: voucher_claim_limit,
      ...rest,
    }))
    .catch((res) => {
      if (res instanceof Error) {
        return Promise.reject(res);
      }
      if (res && res.response && res.response.details) {
        return Promise.reject(Error(res.response.details));
      }
      return Promise.reject(Error('There was an error fetching vouchers'));
    });

  requestUserMessages = () => this.getToken()
    .then(accessToken => this.createAjaxPromise({
      method: 'GET',
      url: `${this.domain}/user/verification/messages/`,
      headers: { Authorization: `Bearer ${accessToken}` },
    }))
    .then((response) => {
      const processMessages = messages => messages
        .map(({ event_author, ...rest }) => ({
          ...rest,
          eventAuthor: event_author,
        }));

      return {
        ...response,
        messages: processMessages(response.messages),
      };
    })
    .catch((res) => {
      if (res instanceof Error) {
        return Promise.reject(res);
      }
      if (res && res.response && res.response.details) {
        return Promise.reject(Error(res.response.details));
      }
      return Promise.reject(Error('There was an error fetching messages'));
    });

  updateUserProfileName = () => this.getToken()
    .then(accessToken => this.createAjaxPromise({
      method: 'PATCH',
      url: `${this.domain}/user/profile/`,
      headers: { Authorization: `Bearer ${accessToken}` },
    }))
    .catch((res) => {
      if (res instanceof Error) {
        return Promise.reject(res);
      }
      if (res && res.response && res.response.details) {
        return Promise.reject(Error(res.response.details));
      }
      return Promise.reject(Error('There was an error updating details'));
    });

  claimUserVoucher = () => this.getToken()
    .then(accessToken => this.createAjaxPromise({
      method: 'POST',
      url: `${this.domain}/voucher/claim/`,
      headers: { Authorization: `Bearer ${accessToken}` },
    }))
    .catch((res) => {
      if (res instanceof Error) {
        return Promise.reject(res);
      }
      if (res && res.response && res.response.details) {
        return Promise.reject(Error(res.response.details));
      }
      return Promise.reject(Error('There was an error claiming code'));
    });

    registerUploadFile = (file, percProgress) => new Promise((res, rej) => {
      const xhr = new XMLHttpRequest();

      if (percProgress) {
        xhr.upload.onprogress = function (e) {
          const percentComplete = (e.loaded / e.total * 100);
          percProgress(percentComplete);
        };
      }

      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          const response = JSON.parse(xhr.response);
          res({
            uploadRef: response.upload_id,
          });
        } else {
          const response = JSON.parse(xhr.response);
          rej(Error(response.detail));
        }
      };

      xhr.onerror = function () {
        rej({
          status: xhr.status,
          statusText: xhr.statusText
        });
      };

      xhr.open('POST', `${this.domain}/user/register/upload/`);
      xhr.setRequestHeader('Content-Type', 'application/octet-stream');
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.setRequestHeader('Content-Disposition', `form-data; name="file"; filename="${file.name}"`);
      xhr.send(file);
    });

    verificationUploadFile = (file, percProgress) => this.getToken()
      .then(accessToken => new Promise((res, rej) => {
        const xhr = new XMLHttpRequest();

        if (percProgress) {
          xhr.upload.onprogress = function (e) {
            const percentComplete = (e.loaded / e.total * 100);
            percProgress(percentComplete);
          };
        }

        xhr.onload = function () {
          if (this.status >= 200 && this.status < 300) {
            const response = JSON.parse(xhr.response);
            res({
              uploadRef: response.upload_id,
            });
          } else {
            const response = JSON.parse(xhr.response);
            rej(Error(response.detail));
          }
        };

        xhr.onerror = function () {
          rej({
            status: xhr.status,
            statusText: xhr.statusText
          });
        };

        xhr.open('POST', `${this.domain}/user/verification/upload/`);
        xhr.setRequestHeader('Content-Type', 'application/octet-stream');
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
        xhr.setRequestHeader('Content-Disposition', `form-data; name="file"; filename="${file.name}"`);
        xhr.send(file);
      }))
      .catch((res) => {
        if (res instanceof Error) {
          return Promise.reject(res);
        }
        if (res && res.response && res.response.details) {
          return Promise.reject(Error(res.response.details));
        }
        return Promise.reject(Error('There was an error uploading attachment'));
      });;

    postMessage = (values) => this.getToken()
      .then(accessToken => this.createAjaxPromise({
        method: 'POST',
        url: `${this.domain}/user/verification/messages/`,
        headers: { Authorization: `Bearer ${accessToken}` },
        body: values,
      }))
      .catch((res) => {
        if (res instanceof Error) {
          return Promise.reject(res);
        }
        if (res && res.response && res.response.details) {
          return Promise.reject(Error(res.response.details));
        }
        return Promise.reject(Error('There was an error updating details'));
      });
}
