import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { rootReducer } from './modules/root';
import rootEpic from './epics/rootEpic';


const middleware = [];
const epicMiddleware = createEpicMiddleware();

middleware.push(epicMiddleware);

const debug = true;
if (debug) {
  // Logs all actions and states after they are dispatched.
  const logger = store => next => (action) => {
    console.group(action.type);
    console.info('dispatching', action);
    const result = next(action);
    console.log('next state', store.getState());
    console.groupEnd(action.type);
    return result;
  };
  middleware.push(logger);
}

const store = createStore(
  rootReducer,
  applyMiddleware(...middleware),
);

epicMiddleware.run(rootEpic);

export default store;
