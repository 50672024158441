import React, { createContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  requestUserProfile,
  requestUserVouchers,
  updateUserProfileName,
  claimUserVoucher,
  requestAuthToken,
  accessToken,
} from '../util/api';
import { fetchMessagesDispatch } from '../redux/modules/messages';
import { logout, login, fetchProfile } from '../redux/modules/auth';

export const AuthContext = createContext();

const mapStateToProps = ({ auth, messages }) => ({
  authenticated: auth.authenticated,
  authError: auth.error,
  profile: auth.profile,
  profileError: auth.profileError,
  messages: messages.messages,
  messagesIsFetching: messages.isFetching,
  messagesErrorMessage: messages.errorMessage,
});

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const {
    authenticated,
    authError,
    profile,
    profileError,
    messages,
    messagesIsFetching,
    messagesErrorMessage,
  } = useSelector(mapStateToProps);

  const [userVouchers, setUserVouchers] = useState([]);
  const [voucherClaimLimit, setVoucherClaimLimit] = useState(6);
  const [isClaiming, setIsClaiming] = useState(false);
  const [linkToken, setLinkToken] = useState(null);
  const [authToken, setAuthToken] = useState(accessToken);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);

  useEffect(() => {
    if (authenticated) {
      setRedirectToReferrer(true);
    }
  }, [authenticated]);

  useEffect(() => {
    if (linkToken && !authToken) {
      dispatch(login(linkToken));
    }
  }, [linkToken]);

  const fetchUserVouchers = () => requestUserVouchers()
    .then((response) => {
      setUserVouchers(response?.vouchers);
      if (response?.voucherClaimLimit) {
        setVoucherClaimLimit(response?.voucherClaimLimit);
      }
    })
    .catch();

  const fetchUserMessages = () => dispatch(fetchMessagesDispatch());

  const updateUserName = () => updateUserProfileName()
    .then(() => {
      dispatch(fetchProfile());
    });

  const claimVoucher = () => {
    setIsClaiming(true);
    return claimUserVoucher()
      .then(response => console.log(response))
      .then(() => fetchUserVouchers())
      .then(() => {
        setIsClaiming(false);
      })
      .catch(() => {
        setIsClaiming(false);
      });
  };

  const logoutUser = () => {
    setLinkToken(null);
    setAuthToken(null);
    dispatch(logout());
  };

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        authError,
        authToken,
        setLinkToken,
        logoutUser,
        profile,
        profileError,
        updateUserName,
        fetchUserVouchers,
        fetchUserMessages,
        userVouchers,
        userMessages: messages,
        claimVoucher,
        voucherClaimLimit,
        isClaiming,
        redirectToReferrer,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
