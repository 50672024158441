import VEAAPI from './vea-api';
import STUBAPI from './stub-api';
import { API_DOMAIN } from './config';


const config = {
  domain: API_DOMAIN,
};

const useStubs = false;
const api = useStubs
  ? new STUBAPI()
  : new VEAAPI(config);


export const {
  requestApplicationPage,
  resumeSession,
  workEmailAuthenticationRequest,
  personalEmailAuthenticationRequest,
  requestAuthToken,
  requestUserProfile,
  updateUserProfileName,
  requestUserVouchers,
  requestUserMessages,
  claimUserVoucher,
  logout,
  accessToken,
  callNextURL,
  isVerified,
  registerUploadFile,
  verificationUploadFile,
  postMessage,
} = api;
