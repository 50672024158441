import { combineEpics } from 'redux-observable';
import auth from './auth';
import pages from './pages';
import messages from './messages';

const rootEpic = combineEpics(
  auth,
  pages,
  messages,
);

export default rootEpic;
