import { combineReducers } from 'redux';
import auth, { LOGOUT_FULFILL } from './auth';
import pages from './pages';
import files from './files';
import messages from './messages';


export const appReducer = combineReducers({
  auth,
  pages,
  files,
  messages,
});


export const rootReducer = (state, action) => {
  if (action.type === LOGOUT_FULFILL) {
    window.location.reload();
  }

  return appReducer(state, action);
};
