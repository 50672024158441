export const FETCH_MESSAGES_DISPATCH = 'VEA/messages/FETCH_MESSAGES_DISPATCHED';
export const FETCH_MESSAGES_FULFILL = 'VEA/messages/FETCH_MESSAGES_FULFILLED';
export const FETCH_MESSAGES_REJECT = 'VEA/messages/FETCH_MESSAGES_REJECTED';

export const fetchMessagesDispatch = () => ({ type: FETCH_MESSAGES_DISPATCH });
export const fetchMessagesFulfill = messages => ({ type: FETCH_MESSAGES_FULFILL, messages });
export const fetchMessagesReject = errorMessage => ({ type: FETCH_MESSAGES_REJECT, errorMessage });

const initialState = {
  isFetching: false,
  messages: [],
  errorMessage: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MESSAGES_DISPATCH:
      return {
        ...state,
        errorMessage: null,
        isFetching: true,
      };

    case FETCH_MESSAGES_FULFILL:
      return {
        ...state,
        isFetching: false,
        messages: action.messages,
      };

    case FETCH_MESSAGES_REJECT:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      };

    default:
      return state;
  }
}
