export const SESSION_RESUME_DISPATCH = 'VEA/auth/SESSION_RESUME_DISPATCH';
export const SESSION_RESUME_FULFILL = 'VEA/auth/SESSION_RESUME_FULFILL';
export const SESSION_RESUME_REJECT = 'VEA/auth/SESSION_RESUME_REJECT';

export const LOGIN_DISPATCH = 'VEA/auth/LOGIN_DISPATCHED';
export const LOGIN_FULFILL = 'VEA/auth/LOGIN_FULFILLED';
export const LOGIN_REJECT = 'VEA/auth/LOGIN_REJECTED';

export const LOGOUT_DISPATCH = 'VEA/auth/LOGOUT_DISPATCHED';
export const LOGOUT_FULFILL = 'VEA/auth/LOGOUT_FULFILLED';
export const LOGOUT_REJECT = 'VEA/auth/LOGOUT_REJECTED';

export const FETCH_PROFILE_DISPATCH = 'VEA/auth/FETCH_PROFILE_DISPATCHED';
export const FETCH_PROFILE_FULFILL = 'VEA/auth/FETCH_PROFILE_FULFILLED';
export const FETCH_PROFILE_REJECT = 'VEA/auth/FETCH_PROFILE_REJECTED';

export const sessionResume = () => ({ type: SESSION_RESUME_DISPATCH });
export const sessionResumeFulfill = () => ({ type: SESSION_RESUME_FULFILL });
export const sessionResumeReject = error => ({ type: SESSION_RESUME_REJECT, error });

export const login = linkToken => ({ type: LOGIN_DISPATCH, linkToken });
export const loginFulfill = () => ({ type: LOGIN_FULFILL });
export const loginReject = error => ({ type: LOGIN_REJECT, error });

export const logout = () => ({ type: LOGOUT_DISPATCH });
export const logoutFulfill = () => ({ type: LOGOUT_FULFILL });
export const logoutReject = error => ({ type: LOGOUT_REJECT, error });

export const fetchProfile = () => ({ type: FETCH_PROFILE_DISPATCH });
export const fetchProfileFulfill = profile => ({ type: FETCH_PROFILE_FULFILL, profile });
export const fetchProfileReject = error => ({ type: FETCH_PROFILE_REJECT, error });


const initialState = {
  profile: null,
  profileFetching: false,
  profileError: null,
  authenticating: false,
  reAuthenticating: false,
  authenticated: false,
  unauthenticating: false,
  error: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SESSION_RESUME_DISPATCH: {
      return {
        ...state,
        authenticating: true,
        reAuthenticating: true,
        authenticated: false,
        unauthenticating: false,
        error: null,
      };
    }
    case SESSION_RESUME_FULFILL: {
      return {
        ...state,
        authenticated: true,
        authenticating: false,
        reAuthenticating: false,
        unauthenticating: false,
        error: null,
      };
    }
    case SESSION_RESUME_REJECT: {
      return {
        ...state,
        authenticated: false,
        authenticating: false,
        reAuthenticating: false,
        // error: action.error.message,
      };
    }
    case LOGIN_DISPATCH:
      return {
        ...state,
        authenticating: true,
        error: null,
      };

    case LOGIN_FULFILL:
      return {
        ...state,
        authenticated: true,
        authenticating: false,
        error: null,
      };
    case LOGIN_REJECT:
      return {
        ...state,
        authenticating: false,
        error: action.error,
      };
    case LOGOUT_DISPATCH:
      return {
        ...state,
        unauthenticating: true,
        error: null,
      };
    case LOGOUT_FULFILL:
      return {
        ...state,
        authenticated: false,
        unauthenticating: false,
        error: null,
      };
    case LOGOUT_REJECT:
      return {
        ...state,
        unauthenticating: false,
        error: action.error,
      };
    case FETCH_PROFILE_DISPATCH:
      return {
        ...state,
        profileFetching: true,
        profileError: null,
      };
    case FETCH_PROFILE_FULFILL:
      return {
        ...state,
        profileFetching: false,
        profile: action.profile,
        profileError: null,
      };
    case FETCH_PROFILE_REJECT:
      return {
        ...state,
        profileError: action.error,
      };

    default:
      return state;
  }
}
