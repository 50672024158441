import { of, from } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { requestApplicationPage } from '../../util/api';
import {
  FETCH_APPLICATION_PAGE_DISPATCH,
  fetchApplicationPageFulfill,
  fetchApplicationPageReject,
} from '../modules/pages';


export const requestApplicationPageEpic = action$ => action$.ofType(FETCH_APPLICATION_PAGE_DISPATCH)
  .pipe(mergeMap(() => from(requestApplicationPage())
    .pipe(
      map(({
        title,
        sub_title,
        hero_body_text,
        how_to_buy_blocks,
        what_can_i_get_blocks,
      }) => fetchApplicationPageFulfill(
        title,
        sub_title,
        hero_body_text,
        how_to_buy_blocks,
        what_can_i_get_blocks,
      )),
      catchError(error => of(
        fetchApplicationPageReject(error.message),
      )),
    )));

export default requestApplicationPageEpic;
